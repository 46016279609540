import { memo } from 'react'
import { Paper, Stack, Typography, useMediaQuery } from '@mui/material'
import { formatNumber, formatCost } from '../../../utils/format'
import { useTheme } from '@emotion/react'

const BuildingsChallenge = ({ latestData }) => {
	const theme = useTheme()
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
	// Keeping the below code in case minds are changed about the design yet again (just until the release)
	// const upfrontCost = formatCost(latestData?.upfront_cost)
	// 	.replace('m', ' Million')
	// 	.replace('b', ' Billion')
	// const costNumber = upfrontCost.substring(0, upfrontCost.indexOf(' '))
	// const costWord = upfrontCost.substring(upfrontCost.indexOf(' ') + 1)

	return (
		<Paper>
			<Stack
				direction='column'
				spacing={0}
				alignItems='center'
				justifyContent='center'
				mt={1}>
				<Typography
					fontSize={isSmallScreen ? '3vw' : '1.7vw'}
					align='center'
					fontWeight='bold'
					mt={1}
					color={theme.palette.dark}>
					BUILDINGS REQUIRING
				</Typography>
				<Typography
					fontSize={isSmallScreen ? '3vw' : '1.7vw'}
					align='center'
					fontWeight='bold'
					mb={1}
					color={theme.palette.dark}>
					CHALLENGE PROCESS REVIEW
				</Typography>
				<Typography
					fontSize={isSmallScreen ? '7vw' : '5vw'}
					fontWeight='bold'
					color='#009599'
					mb={4}>
					{formatNumber(latestData?.served)}
				</Typography>
				<Typography
					fontSize={isSmallScreen ? '3vw' : '1.7vw'}
					align='center'
					fontWeight='bold'
					mt={1}
					mb={1}
					color={theme.palette.dark}>
					ESTIMATED UPFRONT COST
				</Typography>
				<Typography
					fontSize={isSmallScreen ? '7vw' : '5vw'}
					fontWeight='bold'
					color='#009599'>
					{formatCost(latestData?.served_cost)}
				</Typography>
			</Stack>
		</Paper>
	)
}

export default memo(BuildingsChallenge)
