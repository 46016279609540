// Depending on design decisions by program team, this file may be deleted and we will componentize the legend used by NationalMap
const Legend = ({ maxPercentage = 1 }) => {
	const fiftyPercent = Math.round((maxPercentage * 100) / 2)
	const oneHundredPercent = Math.round(maxPercentage * 100)

	return (
		<g id='Legend'>
			<linearGradient
				id='_lgradient_0'
				x1='0'
				y1='0.5'
				x2='1.0000000000000004'
				y2='0.5'
				gradientTransform='matrix(117.4,0,0,4.3,562.2,313.3)'
				gradientUnits='userSpaceOnUse'>
				<stop offset='0%' stopColor='hsl(182, 100%, 0%)' />
				<stop offset='10%' stopColor='hsl(182, 100%, 7.5%)' />
				<stop offset='20%' stopColor='hsl(182, 100%, 15%)' />
				<stop offset='30%' stopColor='hsl(182, 100%, 22.5%)' />
				<stop offset='40%' stopColor='hsl(182, 100%, 30%)' />
				<stop offset='50%' stopColor='hsl(182, 100%, 37.5%)' />
				<stop offset='60%' stopColor='hsl(182, 100%, 45%)' />
				<stop offset='73%' stopColor='hsl(182, 100%, 52.5%)' />
				<stop offset='80%' stopColor='hsl(182, 100%, 60%)' />
				<stop offset='90%' stopColor='hsl(182, 100%, 67.5%)' />
				<stop offset='99%' stopColor='hsl(182, 100%, 75%)' />
			</linearGradient>
			<rect
				x='562.245'
				y='313.319'
				width='117.401'
				height='4.348'
				transform='matrix(1,0,0,1,0,0)'
				id='gradient'
				fill='url(#_lgradient_0)'
			/>
			<g id='100'>
				<mask
					id='_mask_M2a7Il9L5ElbORxKuGwTbbLx0Ibh0J8Y'
					x='-200%'
					y='-200%'
					width='400%'
					height='400%'>
					<rect
						x='-200%'
						y='-200%'
						width='400%'
						height='400%'
						style={{ fill: 'white' }}
					/>
					<line
						x1='679.647'
						y1='317.667'
						x2='679.368'
						y2='306.796'
						id='bar'
						fill='black'
						stroke='none'
					/>
				</mask>
				<line
					x1='679.647'
					y1='317.667'
					x2='679.368'
					y2='306.796'
					id='bar'
					mask='url(#_mask_M2a7Il9L5ElbORxKuGwTbbLx0Ibh0J8Y)'
					vectorEffect='non-scaling-stroke'
					strokeWidth='1'
					stroke='rgb(0,0,0)'
					strokeOpacity='100'
					strokeLinejoin='miter'
					strokeLinecap='butt'
					strokeMiterlimit='3'
				/>
				<g clipPath='url(#_clipPath_972PerxNI9DhzuH2p1Mnvt0RQZRZrqnX)'>
					<text
						transform={
							maxPercentage === 1
								? 'matrix(1,0,0,1,671.281,304.491)'
								: 'matrix(1,0,0,1,674.281,304.491)'
						}
						id='one hundred percent'
						style={{
							fontFamily: 'Lato',
							fontWeight: 400,
							fontSize: '7.5px',
							fontStyle: 'normal',
						}}>
						{oneHundredPercent}
					</text>
				</g>
				<defs>
					<clipPath id='_clipPath_972PerxNI9DhzuH2p1Mnvt0RQZRZrqnX'>
						<rect
							x='0'
							y='0'
							width='20'
							height='10'
							transform='matrix(1,0,0,1,670,296)'
						/>
					</clipPath>
				</defs>
			</g>
			<g id='50%'>
				<mask
					id='_mask_9KqMKjNUm0jF8z440MrjFMIisOejiQDS'
					x='-200%'
					y='-200%'
					width='400%'
					height='400%'>
					<rect
						x='-200%'
						y='-200%'
						width='400%'
						height='400%'
						style={{ fill: 'white' }}
					/>
					<line
						x1='621.224'
						y1='317.667'
						x2='620.946'
						y2='306.796'
						id='bar'
						fill='black'
						stroke='none'
					/>
				</mask>
				<line
					x1='621.224'
					y1='317.667'
					x2='620.946'
					y2='306.796'
					id='bar'
					mask='url(#_mask_9KqMKjNUm0jF8z440MrjFMIisOejiQDS)'
					vectorEffect='non-scaling-stroke'
					strokeWidth='1'
					stroke='rgb(0,0,0)'
					strokeOpacity='100'
					strokeLinejoin='miter'
					strokeLinecap='butt'
					strokeMiterlimit='3'
				/>
				<g clipPath='url(#_clipPath_nbBu6qmKQhOw97zJ01M3wumpOnOGTN0t)'>
					<text
						transform='matrix(1,0,0,1,615.954,304.491)'
						id='fifty percent'
						style={{
							fontFamily: 'Lato',
							fontWeight: 400,
							fontSize: '7.5px',
							fontStyle: 'normal',
						}}>
						{fiftyPercent}
					</text>
				</g>
				<defs>
					<clipPath id='_clipPath_nbBu6qmKQhOw97zJ01M3wumpOnOGTN0t'>
						<rect
							x='0'
							y='0'
							width='14.2'
							height='10'
							transform='matrix(1,0,0,1,615,296)'
						/>
					</clipPath>
				</defs>
			</g>
			<g id='0'>
				<mask
					id='_mask_DENIowros5Dkr5Tg2FrPocZnhgndEvtq'
					x='-200%'
					y='-200%'
					width='400%'
					height='400%'>
					<rect
						x='-200%'
						y='-200%'
						width='400%'
						height='400%'
						style={{ fill: 'white' }}
					/>
					<line
						x1='562.524'
						y1='317.667'
						x2='562.245'
						y2='306.796'
						id='bar'
						fill='black'
						stroke='none'
					/>
				</mask>
				<line
					x1='562.524'
					y1='317.667'
					x2='562.245'
					y2='306.796'
					id='bar'
					mask='url(#_mask_DENIowros5Dkr5Tg2FrPocZnhgndEvtq)'
					vectorEffect='non-scaling-stroke'
					strokeWidth='1'
					stroke='rgb(0,0,0)'
					strokeOpacity='100'
					strokeLinejoin='miter'
					strokeLinecap='butt'
					strokeMiterlimit='3'
				/>
				<g clipPath='url(#_clipPath_XG2wOItCukKxxyZ2B5K8gILL0MDJ5Stm)'>
					<text
						transform='matrix(1,0,0,1,559.627,304.491)'
						id='zero percent'
						style={{
							fontFamily: 'Lato',
							fontWeight: 400,
							fontSize: '7.5px',
							fontStyle: 'normal',
						}}>
						{0}
					</text>
				</g>
				<defs>
					<clipPath id='_clipPath_XG2wOItCukKxxyZ2B5K8gILL0MDJ5Stm'>
						<rect
							x='0'
							y='0'
							width='10'
							height='10'
							transform='matrix(1,0,0,1,558.2,296)'
						/>
					</clipPath>
				</defs>
			</g>
			<g clipPath='url(#_clipPath_REol59Gr0TZVjG8KlNgNrU2niazsespD)'>
				<text
					transform='matrix(0.592,0,0,0.592,571.038,292.432)'
					id='label'
					style={{
						fontFamily: 'Lato',
						fontWeight: 400,
						fontSize: '13.2px',
						fontStyle: 'normal',
					}}>
					Affordability Gap Covered (%)
				</text>
			</g>
			<defs>
				<clipPath id='_clipPath_REol59Gr0TZVjG8KlNgNrU2niazsespD'>
					<rect
						x='0'
						y='0'
						width='176'
						height='24.513'
						transform='matrix(0.592,0,0,0.592,571.038,281.034)'
					/>
				</clipPath>
			</defs>
		</g>
	)
}
export default Legend
