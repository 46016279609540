import { createContext, useContext, useEffect, useReducer } from 'react'
import config from '../../utils/config'
import { setNationalDataAction } from './actions'
import nationalDataReducer from './reducer'

// the Data Context will store all of our data about states and cities
const NationalDataContext = createContext()

/**
 * A provider for our national data context. this component asynchronously fetches local data in
 * order to lessen the load on the client.
 *
 * Note: this data is currently loaded on change to the window path. If we need it in other
 * situations, this behaviour should be abstracted — maybe into a hook.
 */
const NationalDataProvider = ({ children }) => {
	const initialState = []

	const [state, dispatch] = useReducer(nationalDataReducer, initialState)

	// fetch the national data on app load, as we need it for the map
	useEffect(() => {
		// only fetch data if it doesn't exist
		const fetchNationalData = async () => {
			try {
				const response = await fetch(`${config.apiUrl}/adoption-data`)
				const data = await response.json()
				const sortedDataByDateDesc = data.sort(
					(a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
				)
				dispatch(setNationalDataAction(sortedDataByDateDesc))
			} catch (error) {
				console.error(error)
			}
		}
		fetchNationalData()
	}, [])

	return (
		<NationalDataContext.Provider value={[state, dispatch]}>
			{children}
		</NationalDataContext.Provider>
	)
}

/**
 * rather than importing React.useContext and StateContext wherever we want to get state details, we
 * export a hook which does that for us, so we need only one import elsewhere.
 */
const useNationalData = () => {
	const [nationalData] = useContext(NationalDataContext)
	return nationalData
}

export { NationalDataProvider as DataProvider, useNationalData }
