import { memo } from 'react'
import { Paper, Stack, Typography, useMediaQuery } from '@mui/material'
import { formatNumber, formatCost } from '../../../utils/format'
import { useTheme } from '@emotion/react'

const BuildingsFCC = ({ latestData }) => {
	const theme = useTheme()
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
	return (
		<Paper>
			<Stack
				direction='column'
				spacing={0}
				alignItems='center'
				justifyContent='center'
				mt={1}>
				<Typography
					fontSize={isSmallScreen ? '3vw' : '1.7vw'}
					align='center'
					fontWeight='bold'
					mt={1}
					color={theme.palette.dark}>
					BUILDINGS REQUIRED
				</Typography>
				<Typography
					fontSize={isSmallScreen ? '3vw' : '1.7vw'}
					align='center'
					fontWeight='bold'
					mb={1}
					color={theme.palette.dark}>
					BY FCC MAP
				</Typography>
				<Typography
					fontSize={isSmallScreen ? '7vw' : '5vw'}
					fontWeight='bold'
					color='#009599'
					mb={4}>
					{formatNumber(latestData?.under_unserved_prop)}
				</Typography>
				<Typography
					fontSize={isSmallScreen ? '3vw' : '1.7vw'}
					align='center'
					fontWeight='bold'
					mt={1}
					mb={1}
					color={theme.palette.dark}>
					ESTIMATED UPFRONT COST
				</Typography>
				<Typography
					fontSize={isSmallScreen ? '7vw' : '5vw'}
					fontWeight='bold'
					color='#009599'>
					{formatCost(latestData?.under_unserved_cost)}
				</Typography>
			</Stack>
		</Paper>
	)
}

export default memo(BuildingsFCC)
