import { useState, useEffect, useCallback } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Box, Typography, IconButton, useMediaQuery, Link } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { formatNumberForDisplay } from '../../utils/format'
import { Unconnected, BuildingsFCC, BuildingsChallenge } from './charts'
import CityDataTable from './table'
import useMudStateData from '../../hooks/useMudStateData'
import { useMudNationalData } from '../../store/mudNationalData/context'
import StateSilhouette from '../StateSilhouette'
import { useTheme } from '@emotion/react'

const MudStateDetail = () => {
	const [filteredData, setFilteredData] = useState(null)
	const { stateName } = useParams()
	const nationalData = useMudNationalData()
	const stateData = useMudStateData(stateName)
	const navigate = useNavigate()
	const theme = useTheme()
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
	const handleCloseDialog = () => navigate('/mud')

	const latestData = filteredData?.[0]
	const numEligibleHouseholds = latestData?.est_unconn_units

	useEffect(() => {
		if (nationalData) {
			setFilteredData(nationalData.filter((d) => d.state_name === stateName))
		}
	}, [stateName, nationalData])

	/* 
	 the following function and event listeners serve for the escape key to close the dialog and
	 navigate back to the national map, giving it the facade of being a modal dialog
	 */
	const escFunction = useCallback((event) => {
		if (event.key === 'Escape') {
			handleCloseDialog()
		}
	}, [])

	useEffect(() => {
		document.addEventListener('keydown', escFunction, true)
		return () => {
			document.removeEventListener('keydown', escFunction, true)
		}
	}, [])

	// this effect messages our WordPress overlords to update the size of the iframe
	useEffect(() => {
		setTimeout(() => {
			const message = {
				height: document.body.scrollHeight,
				width: document.body.scrollWidth,
				aspectRatio: document.body.scrollWidth / document.body.scrollHeight,
			}
			window.parent.postMessage(message, '*')
		}, 100)
	}, [stateData])

	if (!latestData) return null
	return (
		<Box
			sx={{
				p: 1,
			}}>
			<Box
				sx={{
					display: 'grid',
					width: '100%',
					gridTemplateColumns: '1fr 51px',
				}}>
				<div id='title'>
					<Typography
						sx={{ fontSize: 'min(45pt, 4.5vw)', whiteSpace: 'nowrap' }}
						color='primary'
						fontWeight='900'>
						{stateName} Apartment Wi-Fi Opportunity
					</Typography>
					{numEligibleHouseholds && (
						<Typography
							color='dark'
							sx={{
								fontSize: 'min(24pt, 3vw)',
								whiteSpace: 'nowrap',
								mt: -1.5,
							}}
							gutterBottom
							fontWeight='500'>
							{formatNumberForDisplay(numEligibleHouseholds)} unconnected
							households
						</Typography>
					)}
					<Typography
						color='dark'
						sx={{
							fontSize: 'min(18pt, 1.2vw)',
							whiteSpace: 'nowrap',
						}}>
						See our methodology section{' '}
						<Link
							href='https://www.educationsuperhighway.org/wp-content/uploads/About-The-Data.pdf'
							underline='hover'
							target='_blank'
							rel='noopener'>
							here.
						</Link>
					</Typography>
				</div>
				<Box
					sx={{
						display: 'grid',
						justifyItems: 'end',
						alignItems: 'start',
					}}>
					<IconButton
						edge='start'
						onClick={handleCloseDialog}
						aria-label='close-button'>
						<CloseIcon color='darker' fontSize='large' />
					</IconButton>
				</Box>
			</Box>
			<Box
				sx={{
					display: 'grid',
					gridTemplateColumns: isSmallScreen ? '1fr ' : '1fr 1fr 1fr',
					gridTemplateRows: isSmallScreen ? '1fr 1fr 1fr' : '1fr',
					gap: 3,
					'& > *': {
						borderRadius: '10px !important', // ?? why the heck does this need to be important?
						border: '1px solid',
						borderColor: 'lighter',
						minHeight: '300px',
					},
					mt: 4,
					mb: 6,
				}}>
				<Unconnected latestData={latestData} />
				{stateName !== 'Puerto Rico' && (
					<BuildingsFCC latestData={latestData} />
				)}
				<BuildingsChallenge latestData={latestData} />
			</Box>
			{stateName !== 'Puerto Rico' && <CityDataTable stateData={stateData} />}
			<StateSilhouette stateName={stateName} />
		</Box>
	)
}

export default MudStateDetail
