import { useNationalData } from '../../store/nationalData/context'
import { useMudNationalData } from '../../store/mudNationalData/context'
import StateWithData from './stateWithData'

const State = ({ stateName, isMud, maxPercentage = 1, path, ...args }) => {
	const nationalData = useNationalData()
	const mudNationalData = useMudNationalData()

	return (
		<StateWithData
			nationalData={isMud ? mudNationalData : nationalData}
			maxPercentage={maxPercentage}
			displayProp={
				isMud ? 'affordability_gap_covered_pct' : 'state_adoption_rate'
			}
			stateName={stateName}
			path={path}
			{...args}
		/>
	)
}

export default State
