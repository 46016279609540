import { createTheme } from '@mui/material/styles'

/**
 * this function follows the MUI docs for changing spacing (used for padding and margins)
 * dynamically.
 *
 * @param {Number} factor – the value passed in for margin or padding. fpr example, the factor
 * below would be 4.
 *    sx={{ m: 4 }}
 */
const setSpacing = (factor) => {
	const width = window.innerWidth
	if (width < 600) return 6 * factor
	if (width < 900) return 7 * factor
	else return 8 * factor
}

const theme = createTheme({
	spacing: setSpacing,
	shadows: Array(25).fill('none'),
	palette: {
		// almost black
		dark: '#4A4A4A',
		// almoster black
		darker: '#2F2F2F',
		// grey
		light: '#999',
		lighter: '#b4b4b4',
		lightest: '#e7e7e7',
		primary: {
			/* ESH Teal */
			main: '#009599',
			light: '#54B1B6',
			dark: '#006D65',
		},
		secondary: {
			/* ESH Yellow */
			main: '#F2BA44',
			light: '#F5CD75',
			dark: '#EFAA15',
		},
		info: {
			main: '#5E083E' /* purple */,
			light: '#158acf' /* skyBlue */,
			active: '#47062f' /* purple: 1 shade darker */,
		},
		button: {
			main: '#FDB913',
			dark: '#5F0F40',
		},
		// orange
		bright: '#FFA500',
	},
	typography: {
		fontFamily: 'Lato',
	},
})

export default theme
