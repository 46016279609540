export const stateCodeToName = {
	AL: 'Alabama',
	AK: 'Alaska',
	AZ: 'Arizona',
	AR: 'Arkansas',
	CA: 'California',
	CO: 'Colorado',
	CT: 'Connecticut',
	DE: 'Delaware',
	DC: 'District of Columbia',
	FL: 'Florida',
	GA: 'Georgia',
	HI: 'Hawaii',
	ID: 'Idaho',
	IL: 'Illinois',
	IN: 'Indiana',
	IA: 'Iowa',
	KS: 'Kansas',
	KY: 'Kentucky',
	LA: 'Louisiana',
	ME: 'Maine',
	MD: 'Maryland',
	MA: 'Massachusetts',
	MI: 'Michigan',
	MN: 'Minnesota',
	MS: 'Mississippi',
	MO: 'Missouri',
	MT: 'Montana',
	NE: 'Nebraska',
	NV: 'Nevada',
	NH: 'New Hampshire',
	NJ: 'New Jersey',
	NM: 'New Mexico',
	NY: 'New York',
	NC: 'North Carolina',
	ND: 'North Dakota',
	OH: 'Ohio',
	OK: 'Oklahoma',
	OR: 'Oregon',
	PA: 'Pennsylvania',
	RI: 'Rhode Island',
	SC: 'South Carolina',
	SD: 'South Dakota',
	TN: 'Tennessee',
	TX: 'Texas',
	UT: 'Utah',
	VT: 'Vermont',
	VA: 'Virginia',
	WA: 'Washington',
	WV: 'West Virginia',
	WI: 'Wisconsin',
	WY: 'Wyoming',
}

export const stateNameToCode = {
	Alabama: 'AL',
	Alaska: 'AK',
	Arizona: 'AZ',
	Arkansas: 'AR',
	California: 'CA',
	Colorado: 'CO',
	Connecticut: 'CT',
	Delaware: 'DE',
	'District of Columbia': 'DC',
	Florida: 'FL',
	Georgia: 'GA',
	Hawaii: 'HI',
	Idaho: 'ID',
	Illinois: 'IL',
	Indiana: 'IN',
	Iowa: 'IA',
	Kansas: 'KS',
	Kentucky: 'KY',
	Louisiana: 'LA',
	Maine: 'ME',
	Maryland: 'MD',
	Massachusetts: 'MA',
	Michigan: 'MI',
	Minnesota: 'MN',
	Mississippi: 'MS',
	Missouri: 'MO',
	Montana: 'MT',
	Nebraska: 'NE',
	Nevada: 'NV',
	'New Hampshire': 'NH',
	'New Jersey': 'NJ',
	'New Mexico': 'NM',
	'New York': 'NY',
	'North Carolina': 'NC',
	'North Dakota': 'ND',
	Ohio: 'OH',
	Oklahoma: 'OK',
	Oregon: 'OR',
	Pennsylvania: 'PA',
	'Rhode Island': 'RI',
	'South Carolina': 'SC',
	'South Dakota': 'SD',
	Tennessee: 'TN',
	Texas: 'TX',
	Utah: 'UT',
	Vermont: 'VT',
	Virginia: 'VA',
	Washington: 'WA',
	'West Virginia': 'WV',
	Wisconsin: 'WI',
	Wyoming: 'WY',
}

/* 
 the following arrays of relative state positions are used for to determine the position of the
 popup on the map. find logic for this in the national map component.
 */

export const centralStates = [
	'Kansas',
	'Nebraska',
	'North Dakota',
	'Oklahoma',
	'South Dakota',
	'Texas',
]

export const middleStates = [
	'California',
	'Colorado',
	'Illinois',
	'Indiana',
	'Kentucky',
	'Maryland',
	'Missouri',
	'Nevada',
	'North Carolina',
	'Tennessee',
	'Utah',
	'Virginia',
	'West Virginia',
]

export const westernStates = [
	'Alaska',
	'Arizona',
	'California',
	'Colorado',
	'Hawaii',
	'Idaho',
	'Montana',
	'Nevada',
	'New Mexico',
	'Oregon',
	'Utah',
	'Washington',
	'Wyoming',
]

export const northernStates = [
	'Connecticut',
	'Delaware',
	'District of Columbia',
	'Idaho',
	'Illinois',
	'Indiana',
	'Iowa',
	'Maine',
	'Massachusetts',
	'Michigan',
	'Minnesota',
	'Montana',
	'Nebraska',
	'New Hampshire',
	'New Jersey',
	'New York',
	'North Dakota',
	'Ohio',
	'Oregon',
	'Pennsylvania',
	'Rhode Island',
	'South Dakota',
	'Vermont',
	'Washington',
	'West Virginia',
	'Wisconsin',
	'Wyoming',
]
