import { createContext, useContext, useReducer } from 'react'
import selectionReducer from './reducer'

// the Selection Context will store which state is currently selected
const SelectionContext = createContext()

const SelectionProvider = ({ children }) => {
	const initialState = {}

	const [state, dispatch] = useReducer(selectionReducer, initialState)

	return (
		<SelectionContext.Provider value={[state, dispatch]}>
			{children}
		</SelectionContext.Provider>
	)
}

/**
 * rather than importing React.useContext and SelectionContext wherever we want to get selection details, we
 * export a hook which does that for us, so we need only one import elsewhere.
 */
const useSelection = () => {
	const context = useContext(SelectionContext)
	if (context === undefined) {
		throw new Error('useSelection must be used within a SelectionProvider')
	}
	return context
}

export { SelectionContext, SelectionProvider, useSelection }
