/**
 * This is a Redux-style reducer for the Selection Context.
 *
 * @param {React.state} state – the current Selection state
 * @param {Object} action - an action object
 * @returns {Object} – the new Selection state
 */
const selectionReducer = (state, action) => {
	switch (action.type) {
		case 'setSelection': {
			return { ...state, selection: action.selection }
		}
		default: {
			throw new Error(`Unhandled action type: ${action.type}`)
		}
	}
}

export default selectionReducer
