import { useTheme } from '@emotion/react'
import { Box } from '@mui/system'
import { useEffect, useState } from 'react'
import { stateMap } from '../../constants/states'

const StateSilhouette = ({ stateName }) => {
	const theme = useTheme()
	const [path, setPath] = useState('')
	const [scale, setScale] = useState(1)
	const [translate, setTranslate] = useState([0, 0])

	useEffect(() => {
		const stateDetails = stateMap[stateName]
		const { path } = stateDetails
		setPath(path)
	}, [stateName])

	useEffect(() => {
		// grab the elements we'll need to calculate the scale and translation
		const path = document.getElementById(stateName)
		const title = document.getElementById('title')
		// an the padding on the page as an int so we can do maths on it
		const padding = parseInt(theme.spacing(4))

		// grab our bounding boxes, also for maths
		const pathBoundingBox = path.getBBox()
		const titleBoundingBox = title.getBoundingClientRect()

		// this is the height of the title plus the padding, which is how big we want the state to be
		const desiredHeight = titleBoundingBox.height + padding
		// with all this done, we can calculate the scale needed to make that happen
		const scale = desiredHeight / pathBoundingBox.height
		// and finally, set them
		setScale(scale)
		// the padding needs to be scaled, too, hence the funky looking maths
		setTranslate([-pathBoundingBox.x + padding / scale, -pathBoundingBox.y])
	}, [stateName, path])

	return (
		<Box
			sx={{
				position: 'absolute',
				top: 0,
				left: 0,
				zIndex: -1,
				width: '920px',
				height: '582px',
			}}>
			<svg
				id='map'
				viewBox='0 0 920 582'
				overflow='hidden'
				style={{
					width: '920px',
					height: '582px',
				}}>
				<path
					fill={theme.palette.lightest}
					style={{
						transform: `scale(${scale}) translate(${translate?.[0]}px, ${translate?.[1]}px)`,
					}}
					id={stateName}
					d={path}
				/>
			</svg>
		</Box>
	)
}

export default StateSilhouette
