import {
	Chart,
	LineSeries,
	Title,
} from '@devexpress/dx-react-chart-material-ui'
import { Animation, ArgumentAxis } from '@devexpress/dx-react-chart'
import { formatThousand } from '../../../utils/format'
import { memo } from 'react'
import { ChartTitleWithSpace } from './ChartTitle'

const labelStyle = { fill: '#4A4A4A', fontSize: '1em', fontWeight: 'bold' }

const LineWithLabel = (props) => {
	return (
		<>
			<LineSeries.Path style={{ strokeWidth: '5px' }} {...props} />
			{props.coordinates.map((point) => {
				const { arg, val, value } = point
				return (
					<Chart.Label
						key={value}
						x={arg}
						y={val + -12}
						dominantBaseline='end'
						textAnchor='end'
						style={labelStyle}>
						{formatThousand(value)}
					</Chart.Label>
				)
			})}
		</>
	)
}

const OverTime = ({ stateData }) => {
	const data = stateData
		.map((d) => {
			const newDate = new Date(d.date)
			return {
				...d,
				acp_enrolled: Number(d.acp_enrolled),
				dateTime: newDate.getTime(),
				dateMonth: newDate.toLocaleString('default', { month: 'short' }),
			}
		})
		.sort((a, b) => a.dateTime - b.dateTime)
		.filter((d) => d.acp_enrolled > 0)

	return (
		<Chart
			data={data}
			height={window.innerWidth * 0.3}
			style={{ padding: '10px 10px 25px 20px' }}>
			<Title text='ENROLLMENT OVER TIME' textComponent={ChartTitleWithSpace} />
			<ArgumentAxis />
			<LineSeries
				valueField='acp_enrolled'
				argumentField='dateMonth'
				color={'#009599'}
				seriesComponent={LineWithLabel}
			/>
			<Animation />
		</Chart>
	)
}

export default memo(OverTime)
