/**
 * This is a Redux-style reducer for the Data Context.
 *
 * @param {React.state} state – the current Selection state
 * @param {Object} action - an action object
 * @returns {Object} – the new Selection state
 */
const nationalDataReducer = (state, action) => {
	switch (action.type) {
		case 'setNationalData': {
			return action.nationalData
		}
		default: {
			throw new Error(`Unhandled action type: ${action.type}`)
		}
	}
}

export default nationalDataReducer
