import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import MudMap from './components/MudMap'
import MudStateDetail from './components/MudStateDetail'
import NationalMap from './components/NationalMap'
import StateDetail from './components/StateDetail'
import Providers from './Providers'

const App = (
	<Providers>
		<Router>
			<Routes>
				<Route path='/' element={<NationalMap />} />
				<Route path='/state/:stateName' element={<StateDetail />} />
				<Route path='/mud' element={<MudMap />} />
				<Route path='/mud/state/:stateName' element={<MudStateDetail />} />
			</Routes>
		</Router>
	</Providers>
)

export default App
