import { formatNumberForDisplay } from '../../../utils/format'
import { memo } from 'react'
import { useTheme } from '@emotion/react'
import {
	Paper,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
	Stack,
	useMediaQuery,
} from '@mui/material'
import { useState } from 'react'

const Unconnected = ({ latestData }) => {
	const theme = useTheme()
	const [showHouseholds, setShowHouseholds] = useState(true)
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))

	const switchHandler = () => {
		setShowHouseholds(!showHouseholds)
	}
	console.log('this is a test - will take out')
	return (
		<Paper>
			<Stack
				direction='column'
				height={window.innerWidth * 0.27}
				spacing={0}
				alignItems='center'
				justifyContent='start'
				display='flex'
				mt={1}>
				<Typography
					fontSize={isSmallScreen ? '3vw' : '1.7vw'}
					align='center'
					fontWeight='bold'
					mt={1}
					mb={8}
					color={theme.palette.dark}>
					TOTAL OPPORTUNITY
				</Typography>
				<Typography
					fontSize={isSmallScreen ? '3vw' : '1.7vw'}
					align='center'
					fontWeight='bold'
					mt={1}
					color={
						showHouseholds
							? theme.palette.primary.main
							: theme.palette.info.main
					}>
					{showHouseholds ? 'UNCONNECTED HOUSEHOLDS' : 'UNCONNECTED PEOPLE'}
				</Typography>
				<Typography
					fontSize={isSmallScreen ? '7vw' : '5vw'}
					fontWeight='bold'
					color={
						showHouseholds
							? theme.palette.primary.main
							: theme.palette.info.main
					}>
					{showHouseholds
						? formatNumberForDisplay(latestData?.est_unconn_units)
						: formatNumberForDisplay(latestData?.est_unconn_pop)}
				</Typography>
			</Stack>
			<ToggleButtonGroup
				size='small'
				fullWidth
				value={showHouseholds ? 'Households' : 'Population'}
				exclusive
				sx={{ mt: isSmallScreen ? '50%' : '0%' }}
				onChange={switchHandler}
				aria-label='switch between households and population'>
				<ToggleButton
					value='Households'
					color='primary'
					sx={{ fontWeight: 'bold', border: '2px solid' }}>
					Households
				</ToggleButton>
				<ToggleButton
					value='Population'
					color='info'
					sx={{ fontWeight: 'bold', border: '2px solid' }}>
					Population
				</ToggleButton>
			</ToggleButtonGroup>
		</Paper>
	)
}

export default memo(Unconnected)
