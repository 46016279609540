// docs https://devexpress.github.io/devextreme-reactive/react/chart/demos/pie/doughnut/

import { Chart, PieSeries, Title } from '@devexpress/dx-react-chart-material-ui'
import { Animation } from '@devexpress/dx-react-chart'
import { formatNumberForDisplay } from '../../../utils/format'
import { memo } from 'react'
import ChartTitle from './ChartTitle'

const Enrollment = ({ latestData }) => {
	const chartData = [
		{
			metric: 'enrolled',
			val: Number(latestData?.acp_enrolled),
			color: '#009599',
			yOffset: window.innerWidth * 0.006,
		},
		{
			metric: 'not enrolled',
			val: Number(latestData?.acp_not_enrolled),
			color: '#006D65',
			yOffset: window.innerWidth * -0.02,
		},
	]

	const innerRadius = 0.58
	const outerRadius = 0.4

	const getCoordinates = (startAngle, endAngle, maxRadius) => {
		const angle = startAngle + (endAngle - startAngle) / 2
		return {
			x: maxRadius * Math.sin(angle),
			y: maxRadius * Math.cos(angle),
		}
	}
	const PointComponent = (props) => {
		const {
			startAngle,
			endAngle,
			maxRadius,
			innerRadius,
			outerRadius,
			arg,
			val,
			value,
			index,
		} = props
		const { x, y } = getCoordinates(startAngle, endAngle, maxRadius)
		const newProps = {
			...props,
			color: chartData[index].color,
			innerRadius: index === 0 ? innerRadius + 0.07 * innerRadius : innerRadius,
			outerRadius:
				index === 0 ? outerRadius - 0.025 * outerRadius : outerRadius,
		}
		return (
			<>
				<PieSeries.Point {...newProps} />
				<Chart.Label
					x={arg + x}
					y={val - y + chartData[index].yOffset}
					dominantBaseline='middle'
					textAnchor='middle'
					style={{ fontSize: '1.25em', fontWeight: 'bold' }}>
					{formatNumberForDisplay(value)}
				</Chart.Label>
				<Chart.Label
					x={arg + x - 2}
					y={val - y + 18 + chartData[index].yOffset}
					dominantBaseline='middle'
					textAnchor='middle'
					style={{ fontSize: '1em' }}>
					{chartData[index].metric}
				</Chart.Label>
			</>
		)
	}

	return (
		<Chart height={window.innerWidth * 0.3} data={chartData}>
			<PieSeries
				valueField='val'
				argumentField='metric'
				innerRadius={innerRadius}
				outerRadius={outerRadius}
				pointComponent={PointComponent}
			/>
			<Title text='ENROLLMENT' textComponent={ChartTitle} />
			<Animation />
		</Chart>
	)
}

export default memo(Enrollment)
