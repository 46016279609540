import { useTheme } from '@emotion/react'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useEffect, useState } from 'react'
import { useSelection } from '../../store/selection/context'
import {
	centralStates,
	middleStates,
	northernStates,
	westernStates,
} from '../../utils/constants'

// the consistent styles for the pop up
const popOverBaseStyles = {
	position: 'absolute',
	maxWidth: '40%',
	margin: 'auto',
	padding: 2,
	pointerEvents: 'none',
}

const PopOver = ({ isMud }) => {
	const theme = useTheme()
	const [{ selection }] = useSelection()

	// coords of the bounding box of the state, used for positioning the popover
	const [top, setTop] = useState(null)
	const [bottom, setBottom] = useState(null)
	const [left, setLeft] = useState(null)
	const [right, setRight] = useState(null)
	// the current styles of the popover
	const [popOverStyles, setPopOverStyles] = useState(popOverBaseStyles)
	// the relative positioning of the state
	const isCentral = centralStates.includes(selection?.state_name)
	const isWestern = westernStates.includes(selection?.state_name)
	const isMiddle = middleStates.includes(selection?.state_name)
	const isNorthern = northernStates.includes(selection?.state_name)

	// set the local state for position of the popover
	useEffect(() => {
		if (selection?.state_name) {
			// get the map element bounding box
			const parentBoundingRect = document
				.getElementById('map')
				.getBoundingClientRect()

			// build the css selector for the state
			const stateId = selection.state_name.toLowerCase().replace(' ', '-')
			// get the state element and then its bounding box
			const element = document.getElementById(`${stateId}`)
			const boundingRect = element?.getBoundingClientRect()

			if (boundingRect) {
				// set positioning of the popover based on where the state is in relation to other states
				if (isNorthern) {
					setTop(boundingRect.bottom + 5)
					setBottom(null)
				} else {
					setBottom(parentBoundingRect.height - boundingRect.top + 10)
					setTop(null)
				}

				if (isWestern) {
					setLeft(boundingRect.right - 25)
					setRight(null)
				} else {
					setRight(parentBoundingRect.width - boundingRect.left - 25)
					setLeft(null)
				}

				if (isCentral) {
					setLeft(0)
					setRight(0)
				}
				if (isMiddle) {
					setTop('40%')
					setBottom(null)
				}
			}
		}
	}, [selection])

	// set the styles of the popover when the positioning vars change
	useEffect(() => {
		setPopOverStyles({
			...popOverBaseStyles,
			top,
			bottom,
			left,
			right,
		})
	}, [top, bottom, left, right])

	return (
		<Box sx={popOverStyles}>
			<Box
				sx={{
					borderRadius: '10px 10px 0 0',
					border: '1px solid',
					borderBottom: 'none',
					borderColor: 'lighter',
					backgroundColor: 'lightest',
					px: 3,
					py: 1,
				}}>
				<Typography sx={{ fontWeight: 700, fontSize: '22px' }}>
					{selection.state_name}
				</Typography>
			</Box>
			<Box
				sx={{
					borderRadius: '0 0 10px 10px',
					border: '1px solid',
					borderTop: 'none',
					borderColor: 'lighter',
					backgroundColor: 'white',
					px: 3,
					py: 2,
					fontSize: '18px',
				}}>
				{/* will refractor once data inputs are clear */}
				{isMud
					? selection?.est_unconn_pop && (
							<>
								{`By deploying Free Apartment Wi-Fi, ${selection.state_name} can connect `}
								<Typography
									component='span'
									sx={{ fontWeight: 700, fontSize: '18px' }}>
									{`${Intl.NumberFormat('en-US').format(
										selection.est_unconn_pop
									)}`}
								</Typography>
								{' people, living in '}
								<Typography
									component='span'
									sx={{ fontWeight: 700, fontSize: '18px' }}>
									{`${Intl.NumberFormat('en-US').format(
										selection.est_unconn_units
									)}`}
								</Typography>
								{' households.'}
								<Typography sx={{ color: theme.palette.primary.main, mt: 1 }}>
									Click on the state to learn more
								</Typography>
							</>
					  )
					: selection?.state_adoption_rate && (
							<>
								<Typography
									component='span'
									sx={{ fontWeight: 700, fontSize: '18px' }}>
									{`${Math.round(selection.state_adoption_rate * 100)}%`}
								</Typography>
								{' of '}
								<Typography
									component='span'
									sx={{ fontWeight: 700, fontSize: '18px' }}>
									{`${Intl.NumberFormat('en-US').format(
										selection.households_acp_eligible
									)}`}
								</Typography>
								{' eligible households are receiving the ACP benefit'}
								<Typography sx={{ color: theme.palette.primary.main, mt: 1 }}>
									Click on the state to learn more
								</Typography>
							</>
					  )}
			</Box>
		</Box>
	)
}

export default PopOver
