export const quotes = {
	Alabama: {
		stateName: 'Alabama',
		governorName: 'Kay Ivey',
		governorQuote: `Broadband accessibility continues to be one of the most critical issues facing our state and directly impacts Alabama's economic development efforts, educational outcomes, and so much more. At the state level, we've been able to strategically deploy our limited resources and expand access in numerous rural communities, but many Alabamians still lack reliable access to high-speed broadband or are struggling to cover the cost. The Affordable Connectivity Program (ACP) is a great resource for Alabamians to get help paying for internet and devices and will go a long way helping our state eliminate coverage gaps. If Alabama is going to continue upon its unprecedented upward trajectory, we have to build awareness and work together to close the digital divide.`,
		governorImage: require('./images/Alabama.png'),
	},
	Delaware: {
		stateName: 'Delaware',
		governorName: 'John Carney',
		governorQuote: `Stable, high-speed internet connection is important for all Delaware families, students, and businesses. The Affordable Connectivity program is an important step to giving Delawareans across the state access to connect to school, work, health care, and more.`,
		governorImage: require('./images/Delaware.png'),
	},
	Hawaii: {
		stateName: 'Hawaii',
		governorName: 'David Ige',
		governorQuote: `Programs like the ACP are building the momentum to bring us all into the 21st century. Whether it's broadband affordability programs, access to devices, digital literacy instruction and most importantly, the environment that builds confidence and dignity, this has the potential to help thousands of Hawaiʻi residents gain access to the digital world.`,
		governorImage: require('./images/Hawaii.png'),
	},
	Iowa: {
		stateName: 'Iowa',
		governorName: 'Kim Reynolds',
		governorQuote: `When I took office, we quickly set to work delivering the reliable and affordable broadband every community needs to compete in the modern economy. Thanks to a historic $360 million investment we made in 2020, an estimated 115,000 homes, schools, and businesses now enjoy access to this essential precondition for prosperity. By bridging Iowa’s digital divide, we’ve laid the groundwork for a flurry of investment, opportunity, and innovation in our rural communities and across the state.`,
		governorImage: require('./images/Iowa.png'),
	},
	Illinois: {
		stateName: 'Illinois',
		governorName: 'J. B. Pritzker',
		governorQuote: `In the 21st century, broadband access isn't a privilege—it's a right. From education to healthcare to government services, high-speed reliable internet access is fundamental towards building a more equitable and economically prosperous state and nation. Thanks to the Affordable Connectivity Program, we are closing the digital divide so that no Illinois family has to choose between paying a broadband bill and putting food on the table.`,
		governorImage: require('./images/Illinois.png'),
	},
	Kansas: {
		stateName: 'Kansas',
		governorName: 'Laura Kelly',
		governorQuote: `Access to high-speed internet in today's economy isn't a luxury – it's a necessity. The Affordable Connectivity Program breaks down barriers to tools that can change outcomes for rural and urban regions alike – something that's essential as Kansas continues to work toward being a top 10 state for broadband access by 2030.`,
		governorImage: require('./images/Kansas.png'),
	},
	Kentucky: {
		stateName: 'Kentucky',
		governorName: 'Andy Beshear',
		governorQuote: `High-speed, reliable internet service is not just the infrastructure of the future, it is the infrastructure of the present. It is essential to the success of our state's economy and to future job creation. It is as vital to connectivity as roads and bridges are. As we strive to build a better Kentucky, the Affordable Connectivity Program is another great option that helps our people get online, where they can access new opportunities, see a doctor through a telehealth visit, connect with loved ones and so much more.`,
		governorImage: require('./images/Kentucky.png'),
	},
	Maine: {
		stateName: 'Maine',
		governorName: 'Janet Mills',
		governorQuote: `The Affordable Connectivity Program can help Maine families save money on the internet connection they need to work, study, see a doctor, and keep in touch with friends and family. This powerful resource will help our state reach my goal of ensuring anyone in our state who wants to connect to the internet will be able to do so by the end of 2024.`,
		governorImage: require('./images/Maine.png'),
	},
	Maryland: {
		stateName: 'Maryland',
		governorName: 'Wes Moore',
		governorQuote: `Universal broadband access is essential in creating a more economically competitive and equitable Maryland. Every Marylander deserves access to high speed internet. The Affordable Connectivity Program has provided Maryland with a once-in-a-generation opportunity to build broadband network infrastructure in unconnected communities across our state, both urban and rural. My administration’s priority is to ensure that we are seizing this opportunity and expanding the program in the most effective and equitable way possible.`,
		governorImage: require('./images/Maryland.png'),
	},
	Massachusetts: {
		stateName: 'Massachusetts',
		governorName: 'Charlie Baker',
		governorQuote: `The internet is a critical tool for learning, working, and staying connected. Since taking office, the Baker-Polito Administration has prioritized expanding broadband internet to previously unserved and underserved communities across central and western Massachusetts, and we have worked hard to extend connectivity to more than 40,000 people in those regions. The federal Affordable Connectivity Program is an important resource in helping further these efforts to close Massachusetts' digital divide and we will continue to support its adoption across the Commonwealth.`,
		governorImage: require('./images/Massachusetts.png'),
	},
	Michigan: {
		stateName: 'Michigan',
		governorName: 'Gretchen Whitmer',
		governorQuote: `With affordable high-speed internet as our tool, we can grow our economy, create jobs, and improve healthcare and education outcomes. We are proud of our investments to connect over 18,000 homes and small businesses across Michigan, and we are committed to achieving our ambitious goal to connect every Michigan community. We are grateful for EducationSuperHighway, and we look forward to our continued partnership to increase Affordable Connectivity Program awareness and adoption.`,
		governorImage: require('./images/Michigan.png'),
	},
	Minnesota: {
		stateName: 'Minnesota',
		governorName: 'Tim Walz',
		governorQuote: `Every household in Minnesota deserves the opportunity to improve their quality of life with access to high-speed internet. The Affordable Connectivity Program will help connect families to affordable broadband and deliver tangible change. I am grateful for the community-based organizations and institutions helping to reach eligible Minnesotans.`,
		governorImage: require('./images/Minnesota.png'),
	},
	Mississippi: {
		stateName: 'Mississippi',
		governorName: 'Tate Reeves',
		governorQuote: `A person's zip code shouldn't limit their access to the full breadth of benefits technology has to offer. Broadband plays a critical role in connecting Mississippians to high-quality education, healthcare, job opportunities, and more. My administration will continue working to ensure that every Mississippi family has the opportunity to get connected with the technology that is helping to power the economy of tomorrow.`,
		governorImage: require('./images/Mississippi.png'),
	},
	Missouri: {
		stateName: 'Missouri',
		governorName: 'Mike Parson',
		governorQuote: `Increasing internet access for families across Missouri is a priority for our administration and critical to the future economic growth of our state. We know that many families, farms, and businesses still lack high-speed internet access. We want to change that. We want more Missourians connected. That’s why we have allocated nearly $400 million this year to develop and expand broadband access and are committed to increasing awareness of available opportunities for Missourians to get connected. If we can put electricity in every home, we can do the same today with broadband.`,
		governorImage: require('./images/Missouri.png'),
	},
	'New Jersey': {
		stateName: 'New Jersey',
		governorName: 'Phil Murphy',
		governorQuote:
			'As we make critical infrastructure investments across the country, we must improve not only the roads that bring our residents to work and school, but the broadband that connects our community members to opportunities and each other. Bridging the digital divide requires an all-of-government approach to capitalizing on the full array of resources at our disposal, including the Affordable Connectivity Program (ACP). By expanding ACP enrollment, we will ensure that every New Jerseyan can reap the benefits of our growing economy and world-class education system.',
		governorImage: require('./images/NewJersey.png'),
	},
	'New Mexico': {
		stateName: 'New Mexico',
		governorName: 'Michelle Lujan Grisham',
		governorQuote: `It is absolutely essential that New Mexicans living in rural and Tribal communities across our state have access to high-speed, reliable, and affordable internet to stay connected to education, healthcare, economic opportunities, and their communities. New Mexico is committed to providing internet access to every New Mexican and the Affordable Connectivity Program will bolster the work we are doing at the state level.`,
		governorImage: require('./images/NewMexico.png'),
	},
	'New York': {
		stateName: 'New York',
		governorName: 'Kathy Hochul',
		governorQuote: `The COVID-19 pandemic has shown us that high-speed internet is more than just a luxury — it's a basic right. Far too many New Yorkers in our most vulnerable communities live disconnected, struggling to access essential services like school or health care. The Affordability Connectivity Program is a major step toward bridging the digital divide and helping ensure that every New Yorker has reliable and affordable internet. `,
		governorImage: require('./images/NewYork.png'),
	},
	'North Carolina': {
		stateName: 'North Carolina',
		governorName: 'Roy Cooper',
		governorQuote: `High-speed internet is critical to helping people live, learn, access health care and grow their businesses. The Affordable Connectivity Program is an important step toward closing the digital divide, and North Carolina is all in on expanding access to broadband in communities across our state.`,
		governorImage: require('./images/NorthCarolina.png'),
	},
	Ohio: {
		stateName: 'Ohio',
		governorName: 'Mike DeWine',
		governorQuote: `High-speed internet is a necessity – not just a luxury. As our world becomes increasingly digital, affordable internet access is needed to expand every citizen's economic opportunity and security. Fortunately, the new Affordable Connectivity Program helps connect households with internet service. My administration is committed to ensuring we seize this opportunity to bring digital equity and affordable high-speed internet connectivity to every community in our state.`,
		governorImage: require('./images/Ohio.png'),
	},
	Oregon: {
		stateName: 'Oregon',
		governorName: 'Tina Kotek',
		governorQuote: `Access to reliable and affordable internet, affordable computing devices, and 21st century digital skills is critical to a community’s local economy. Reliable broadband infrastructure will help to remedy the digital divide in rural and urban communities across the state, ensuring that all Oregonians are able to access education, telehealth, job opportunities, and so much more. The Affordable Connectivity Program is an instrumental part in achieving that goal.`,
		governorImage: require('./images/Oregon.png'),
	},
	'Rhode Island': {
		stateName: 'Rhode Island',
		governorName: 'Daniel McKee',
		governorQuote: `No family in our state should worry about choosing between paying a broadband bill and paying rent or buying groceries. The new Affordable Connectivity Program (ACP) provides households with $30 per month toward broadband service for eligible households and a $100 discount on a laptop, desktop computer, or tablet. We are committed to closing the digital divide by driving awareness of this vital program.`,
		governorImage: require('./images/RhodeIsland.png'),
	},
	Tennessee: {
		stateName: 'Tennessee',
		governorName: 'Bill Lee',
		governorQuote:
			'As people move to Tennessee from across the nation in record numbers, we have an obligation to prepare our state for continued growth. Strong investments in broadband infrastructure and addressing affordability will ensure families and businesses across all of our communities are connected and have every opportunity to thrive.',
		governorImage: require('./images/Tennessee.png'),
	},
	Texas: {
		stateName: 'Texas',
		governorName: 'Greg Abbott',
		governorQuote: `Reliable, high-speed internet is an essential part of providing world-class education, healthcare, and business opportunities to hardworking Texans across the state. Last year, I made broadband expansion an emergency item during the legislative session and as a result I signed six broadband bills, including appropriating more than $500 million, to significantly expand broadband access throughout Texas. Through programs like the Affordable Connectivity Program, more families and businesses will be able to afford high-speed internet as we continue shrinking the digital gap so that every Texan – and American – has the opportunity to succeed.`,
		governorImage: require('./images/Texas.png'),
	},
	Utah: {
		stateName: 'Utah',
		governorName: 'Spencer Cox',
		governorQuote: `The massive shifts in remote work we've seen in the past two years make broadband access an increasingly essential tool for our workforce and our students. Utah has made enormous progress in extending broadband access throughout the state, but we still have work to do, and we appreciate all those helping to make broadband both accessible and affordable.`,
		governorImage: require('./images/Utah.png'),
	},
	Vermont: {
		stateName: 'Vermont',
		governorName: 'Phil Scott',
		governorQuote: `High-speed internet access is critical in the 21st Century economy, and it's essential that we work to bring access to every corner of our states. Here in Vermont, it's a top priority, and we've dedicated hundreds of millions of dollars to advance our efforts to get to the last mile. Additionally, the Affordable Connectivity Program (ACP) is helping to make our new connectivity affordable for everyone. For rural states like mine, bringing reliable high-speed access to every community will not only improve quality of life, but generate economic growth, making our villages and towns more vibrant and resilient.`,
		governorImage: require('./images/Vermont.png'),
	},
	'West Virginia': {
		stateName: 'West Virginia',
		governorName: 'Jim Justice',
		governorQuote: `Education is the centerpiece of everything we do in West Virginia, and in today’s world that means making sure every single one of our students has access to the high-speed Internet connectivity they need to take full advantage of digital learning in the classroom. My administration has been working hard to bring computer science education into every school and to bring broadband to every corner of our state, especially in some of our more rural areas. But we still have a ways to go, and by maximizing the federal government's new Affordable Connectivity Program we have an opportunity to get eligible households in our state needed help to pay for internet. By using all the resources at our disposal, I truly believe that our wonderful students in West Virginia will have an incredibly bright future.`,
		governorImage: require('./images/WestVirginia.png'),
	},
	Nevada: {
		stateName: 'Nevada',
		governorName: 'Joe Lombardo',
		governorQuote: `Internet access is essential to the lives of Nevadans, and it’s especially critical in ensuring access to education, healthcare, and access to government services. We need to make sure all Nevadans have a chance to connect, which is why we are committed to helping families access high-speed internet through the Affordable Connectivity Program.`,
		governorImage: require('./images/Nevada.png'),
	},
	Wisconsin: {
		stateName: 'Wisconsin',
		governorName: 'Tony Evers',
		governorQuote: `Access to affordable high-speed internet is no longer a luxury—it’s a necessity to learn, work, and run a business, and that’s why getting more folks connected and bridging digital divides has been a top priority for me and my administration. Our investments since 2019 are connecting more than 387,000 homes and businesses to reliable, high-speed internet, and the Affordable Connectivity Program will help us keep working to ensure every Wisconsinite can get connected without breaking the bank.`,
		governorImage: require('./images/Wisconsin.png'),
	},
	Idaho: {
		stateName: 'Idaho',
		governorName: 'Brad Little',
		governorQuote: `Improved internet access propels our economy forward, makes education and healthcare more accessible, and greatly improves Idahoans' quality of life. The Affordable Connectivity Program, combined with our historic investments in broadband across the state, help us achieve our goal of getting every Idahoan connected.`,
		governorImage: require('./images/Idaho.png'),
	},
	Louisiana: {
		stateName: 'Louisiana',
		governorName: 'John Bel Edwards',
		governorQuote: `Internet access is essential for so many important areas of daily life including education, helping businesses, creating jobs as well as expanding access to healthcare and government services. We need to make sure everyone, everywhere, has a chance to connect, which is why we are committed to helping families cover the costs of high-speed internet through the Affordable Connectivity Program.`,
		governorImage: require('./images/Louisiana.png'),
	},
}

export default quotes
