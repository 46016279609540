import { useState } from 'react'
import config from '../utils/config'

/**
 * this hook is used for fetching memoized state data from the server
 *
 * @param {String} stateName - come on now.
 */
const useStateData = (stateName) => {
	const [dataByState, setDataByState] = useState({})

	// only fetch data if it doesn't exist
	if (!dataByState[stateName]) {
		const fetchStateData = async () => {
			const response = await fetch(
				`${config.apiUrl}/adoption-data/${stateName}`
			)
			const stateData = await response.json()
			setDataByState({ ...dataByState, [stateName]: stateData })
		}
		fetchStateData()
	}

	return dataByState[stateName]
}

export default useStateData
