// mui imports
import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { MudDataProvider } from './store/mudNationalData/context'
import { DataProvider } from './store/nationalData/context'
import { SelectionProvider } from './store/selection/context'
import theme from './theme'

const Providers = ({ children }) => {
	return (
		<CssBaseline>
			<ThemeProvider theme={theme}>
				<SelectionProvider>
					<DataProvider>
						<MudDataProvider>{children}</MudDataProvider>
					</DataProvider>
				</SelectionProvider>
			</ThemeProvider>
		</CssBaseline>
	)
}

export default Providers
