import { alpha, styled } from '@mui/material/styles'

import { DataGrid, gridClasses } from '@mui/x-data-grid'

const evenOpacity = 0.4

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
	'& .MuiDataGrid-columnSeparator': {
		display: 'none',
	},
	'& .MuiDataGrid-columnHeaderTitle': {
		textTransform: 'uppercase',
		fontWeight: '600',
	},
	[`& .${gridClasses.row}.odd`]: {
		backgroundColor: theme.palette.lightest,
		'&:hover, &.Mui-hovered': {
			backgroundColor: alpha(theme.palette.lighter, evenOpacity),
			'@media (hover: none)': {
				backgroundColor: 'transparent',
			},
		},
		'&.Mui-selected': {
			backgroundColor: alpha(
				theme.palette.lighter,
				evenOpacity + theme.palette.action.selectedOpacity
			),
			'&:hover, &.Mui-hovered': {
				backgroundColor: alpha(
					theme.palette.lighter,
					evenOpacity +
						theme.palette.action.selectedOpacity +
						theme.palette.action.hoverOpacity
				),
				// Reset on touch devices, it doesn't add specificity
				'@media (hover: none)': {
					backgroundColor: alpha(
						theme.palette.lighter,
						evenOpacity + theme.palette.action.selectedOpacity
					),
				},
			},
		},
	},
}))

export default StripedDataGrid
