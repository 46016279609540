import { Title } from '@devexpress/dx-react-chart-material-ui'
import { useTheme } from '@emotion/react'
import { memo } from 'react'

export const ChartTitle = ({ sx, ...args }) => {
	const theme = useTheme()
	return (
		<Title.Text
			{...args}
			style={{
				color: theme.palette.dark,
				fontWeight: 'bold',
				fontSize: '1.5em',
				...sx,
			}}
		/>
	)
}

export const ChartTitleWithSpace = ({ sx, ...args }) => {
	return <ChartTitle sx={{ marginBottom: '50px', ...sx }} {...args} />
}

export default memo(ChartTitle)
