import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { setSelectionAction } from '../../store/selection/actions'
import { useSelection } from '../../store/selection/context'

const tealHue = 182
const goldHue = 47
const lightnessScale = 60

export const getFill = ({ hue = tealHue, saturation = 100, lightness }) =>
	`hsl(${hue}, ${saturation}%, ${lightness}%)`

const StateWithData = ({
	stateName,
	nationalData,
	maxPercentage = 1,
	path,
	displayProp,
	...args
}) => {
	const [stateData, setStateData] = useState(null)
	const [lightness, setLightness] = useState(100)
	const [hue, setHue] = useState(tealHue)
	const [fill, setFill] = useState(getFill(lightness))
	const [selection, selectionDispatch] = useSelection()
	const navigate = useNavigate()

	useEffect(() => {
		if (nationalData) {
			setStateData(nationalData.find((d) => d?.state_name === stateName))
		}
	}, [nationalData, stateName])

	useEffect(() => {
		if (stateData) {
			// here, we are setting the max affordability gap covered percentage as the top of the scale
			setLightness((stateData?.[displayProp] / maxPercentage) * lightnessScale)
		}
	}, [stateData])

	useEffect(() => {
		setFill(getFill({ lightness, hue }))
	}, [hue, lightness])

	const handleMouseOver = () => {
		if (
			stateData?.state_name &&
			stateData?.state_name !== selection?.state_name
		) {
			selectionDispatch(setSelectionAction(stateData))
			setHue(goldHue)
			setLightness(50)
		}
	}

	const handleMouseLeave = () => {
		selectionDispatch(setSelectionAction(null))
		setHue(tealHue)
		// here, we are setting the max affordability gap covered percentage as the top of the scale
		setLightness((stateData?.[displayProp] / maxPercentage) * lightnessScale)
	}

	const onClick = () => {
		navigate(`state/${stateName}`)
	}

	return (
		<path
			fill={fill}
			style={{ transition: 'all .4s ease' }}
			onMouseOver={handleMouseOver}
			onMouseLeave={handleMouseLeave}
			onClick={onClick}
			stroke='white'
			strokeWidth={0.5}
			strokeLinecap='round'
			strokeLinejoin='round'
			id={stateName.toLowerCase().replace(' ', '-')}
			d={path}
			{...args}
		/>
	)
}

export default StateWithData
