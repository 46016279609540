/*
 This file determines which environment we’re in, and provides the app with the appropriate config
 */

export const defaults = {
	port: 1234,
	apiUrl: 'https://edinlscgcg.execute-api.us-east-2.amazonaws.com',
}

export const dev = {}

export const ci = {}

export const prod = {}

export const config = (environment = process.env.NODE_ENV) => {
	switch (environment) {
		case 'production':
			return prod
		case 'ci':
			return ci
		default:
			return dev
	}
}

export default {
	...defaults,
	...config(),
}
