import { Box, Button, Typography } from '@mui/material/'
import {
	GridToolbarContainer,
	GridToolbarFilterButton,
	useGridApiContext,
} from '@mui/x-data-grid'
import { formatCurrency, formatNumber } from '../../../utils/format'
import StripedDataGrid from './StripedDataGrid'

/**
 * a (simpler) custom toolbar that has only the export functionality
 */
const CustomToolbar = () => {
	const apiRef = useGridApiContext()

	return (
		<GridToolbarContainer>
			<Box
				sx={{
					display: 'grid',
					gridTemplateColumns: '1fr auto',
					gap: 3,
					width: '100%',
				}}>
				<Box>
					<GridToolbarFilterButton />
				</Box>
				<Button
					variant='contained'
					color='button'
					sx={{
						color: 'dark',
						fontWeight: 600,
						textTransform: 'uppercase',
						whiteSpace: 'nowrap',
						padding: '8px 30px',
						borderRadius: '100vh',
						mb: 1,
						'&:hover': {
							color: '#fff',
						},
					}}
					onClick={() => {
						apiRef.current.exportDataAsCsv()
					}}>
					Download
				</Button>
			</Box>
		</GridToolbarContainer>
	)
}

/**
 * a component for the city data table in the state detail page
 *
 * @prop {Object} stateData - the state data from the API
 */
const CityDataTable = ({ stateData }) => {
	const columns = [
		{
			field: 'place_id',
			headerName: 'ID',
			hide: true, // the user does not need to see the id, so we'll hide it
			filterable: false,
		},
		{
			field: 'place_name',
			headerName: 'City',
			renderHeader: () => (
				<>
					<Typography
						color='dark'
						sx={{ fontSize: '24pt', whiteSpace: 'nowrap', pb: '16px' }}
						fontWeight='500'>
						Data By City
					</Typography>
				</>
			),
			flex: 0.4,
			minWidth: 200,
		},
		{
			field: 'total_properties',
			headerName: 'Eligible Properties',
			valueFormatter: ({ value }) => formatNumber(value),
			align: 'right',
			headerAlign: 'right',
			type: 'number',
			flex: 0.21,
			minWidth: 110,
		},
		{
			field: 'total_units',
			headerName: 'Total MDU Households',
			valueFormatter: ({ value }) => formatNumber(value),
			align: 'right',
			headerAlign: 'right',
			type: 'number',
			flex: 0.26,
			minWidth: 115,
		},
		{
			field: 'place_total_population',
			headerName: 'Total Population',
			valueFormatter: ({ value }) => formatNumber(value),
			align: 'right',
			headerAlign: 'right',
			type: 'number',
			flex: 0.2,
			minWidth: 130,
		},
		{
			field: 'est_unconn_units',
			headerName: 'Unconnected MDU Households',
			valueFormatter: ({ value }) => formatNumber(value),
			align: 'right',
			headerAlign: 'right',
			type: 'number',
			flex: 0.34,
			minWidth: 115,
		},
		{
			field: 'place_unconn_pop',
			headerName: 'Unconnected Population',
			valueFormatter: ({ value }) => formatNumber(value),
			align: 'right',
			headerAlign: 'right',
			type: 'number',
			flex: 0.29,
			minWidth: 130,
		},
		{
			field: 'upfront_cost',
			headerName: 'Upfront Costs',
			renderCell: ({ value }) => <strong> {formatCurrency(value)} </strong>,
			align: 'right',
			headerAlign: 'right',
			type: 'number',
			flex: 0.15,
			minWidth: 130,
		},
	]

	return (
		<Box sx={{ display: 'grid' }}>
			<StripedDataGrid
				autoHeight
				disableColumnMenu
				columns={columns}
				rows={stateData?.cities || []}
				getRowId={(row) => row?.place_name}
				components={{
					Toolbar: CustomToolbar,
				}}
				pageSize={10}
				rowsPerPageOptions={[10]}
				getRowClassName={(params) =>
					params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
				}
				loading={!stateData?.cities}
				componentsProps={{
					panel: {
						sx: {
							top: '-119px !important',
							left: '0 !important',
						},
					},
				}}
				sx={{
					border: 'none',
					'& .MuiDataGrid-columnHeaderTitle': {
						fontWeight: 600,
						color: 'dark',
						textTransform: 'uppercase',
						whiteSpace: 'break-spaces',
						lineHeight: '1.3',
					},
				}}
			/>
		</Box>
	)
}

export default CityDataTable
