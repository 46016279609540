import { Box } from '@mui/system'
import { useEffect, useState } from 'react'
// import quotes from '../../assets/quotes'
import { useSelection } from '../../store/selection/context'
import State from '../State'
import Legend from './legend'
import { states } from '../../constants/states'
import PopOver from '../Popover'
import { useMudNationalData } from '../../store/mudNationalData/context'

// todo edit the svg to remove the need for this view box
const MudMap = () => {
	// the selected state
	const [{ selection }] = useSelection()
	const [maxPercentage, setMaxPercentage] = useState(0)
	const mudNationalData = useMudNationalData()
	// per request, we will search for the highest affordability gap covered percentage use that as the max
	useEffect(() => {
		const maxPercentage = Math.max(
			...mudNationalData.map((d) => d.affordability_gap_covered_pct),
			0
		)
		setMaxPercentage(maxPercentage)
	}, [mudNationalData])

	// this effect messages our WordPress overlords to update the size of the iframe
	useEffect(() => {
		setTimeout(() => {
			const message = {
				height: document.body.scrollHeight,
				width: document.body.scrollWidth,
				aspectRatio: document.body.scrollWidth / document.body.scrollHeight,
			}
			window.parent.postMessage(message, '*')
		}, 100)
	}, [])

	return (
		<Box
			sx={{
				position: 'relative',
				// keep the same dimensions as the map
				maxWidth: '158vh',
				position: 'relative',
			}}>
			<svg
				id='map'
				viewBox='0 0 690 420'
				overflow='hidden'
				style={{
					maxHeight: '100vh',
					maxWidth: '100vw',
				}}>
				{states.map((params, index) => (
					// to customize the state map, we pass in a prop called isMud that will tell the State component to use the mud map colors
					<State key={index} maxPercentage={maxPercentage} isMud {...params} />
				))}
				<Legend maxPercentage={maxPercentage} />
			</svg>
			{selection?.state_name && <PopOver isMud />}
		</Box>
	)
}

export default MudMap
