import numeral from 'numeral'

export function formatNumber(number, format = '0,0') {
	return number && numeral(number).format(format)
}

export function formatCurrency(number) {
	return formatNumber(number, '($0,0)')
}

export function formatNumberForDisplay(number) {
	if (number > 0) {
		return formatNumber(number)
	}
	if (Number.isNaN(number)) {
		return 'Unknown'
	}
	return number
}

export function formatPercent(decimal) {
	if (decimal < 0.05) return '< 1%'
	return decimal && `${(decimal * 100).toFixed(0)}%`
}

export function formatThousand(number) {
	if (number >= 1000000) {
		return formatNumber(number, '0.00a')
	} else {
		return formatNumber(number, '0a')
	}
}

export function formatCost(number) {
	return formatNumber(number, '($0.[0] a)')
}
