import { useTheme } from '@emotion/react'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useEffect } from 'react'
import quotes from '../../assets/quotes'
import { useSelection } from '../../store/selection/context'
import { clamp } from '../../utils/css'
import State from '../State'
import Legend from './legend'
import PopOver from '../Popover'
import { stateCapitals, states } from '../../constants/states'

// get the state capital paths from just the states with gov. quotes
const capitalsToDraw = Object.keys(quotes).map(
	(stateName) => stateCapitals[stateName]
)

// todo edit the svg to remove the need for this view box
const NationalMap = () => {
	const theme = useTheme()
	// the selected state
	const [{ selection }] = useSelection()

	// this effect messages our WordPress overlords to update the size of the iframe
	useEffect(() => {
		setTimeout(() => {
			const message = {
				height: document.body.scrollHeight,
				width: document.body.scrollWidth,
				aspectRatio: document.body.scrollWidth / document.body.scrollHeight,
			}
			window.parent.postMessage(message, '*')
		}, 100)
	}, [])

	return (
		<Box
			sx={{
				position: 'relative',
				// keep the same dimensions as the map
				maxWidth: '158vh',
				position: 'relative',
			}}>
			<svg
				id='map'
				viewBox='0 0 690 420'
				overflow='hidden'
				style={{
					maxHeight: '100vh',
					maxWidth: '100vw',
				}}>
				{states.map((params, index) => (
					<State key={index} mud={false} {...params} />
				))}
				{
					// draw the state capital paths for the states with quotes
					capitalsToDraw.map(({ path, stateName }) => {
						// if the state is selected, invert the color
						const starColor =
							stateName === selection?.state_name
								? theme.palette.primary.dark
								: theme.palette.secondary.main
						return (
							<path
								d={path}
								style={{ transition: 'all .4s ease' }}
								key={stateName}
								fill={starColor}
								stroke={starColor}
								strokeWidth={2}
								pointerEvents={'none'}
							/>
						)
					})
				}
				<Legend />
			</svg>
			<Typography sx={{ mt: 1, fontSize: clamp('12pt') }}>
				<Typography
					component='span'
					sx={{
						color: theme.palette.secondary.main,
						WebkitTextStrokeColor: theme.palette.primary.main,
						WebkitTextStrokeWidth: '1.5px',
						ml: 1,
					}}>
					★
				</Typography>{' '}
				Governor has made ACP adoption a priority.
			</Typography>
			{selection?.state_name && <PopOver isMud={false} />}
		</Box>
	)
}

export default NationalMap
