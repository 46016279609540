import {
	Chart,
	BarSeries,
	Title,
	ArgumentAxis,
} from '@devexpress/dx-react-chart-material-ui'
import { Animation } from '@devexpress/dx-react-chart'
import { formatPercent } from '../../../utils/format'
import { memo } from 'react'
import { ChartTitleWithSpace } from './ChartTitle'

const National = ({ stateName, latestData }) => {
	const data = [
		{
			metric: stateName,
			val: Number(latestData.state_adoption_rate),
			color: '#009599',
		},
		{
			metric: 'National',
			val: Number(latestData.national_adoption_rate),
			color: '#4A4A4A',
		},
	]

	const labelStyle = { fill: '#4A4A4A', fontSize: '1.5em', fontWeight: 'bold' }

	const BarWithLabel = (props) => {
		const { arg, val, style, value, index } = props
		const newProps = {
			...props,
			color: data[index].color,
			style: { ...style },
		}
		return (
			<>
				<BarSeries.Point {...newProps} />
				<Chart.Label
					x={arg}
					y={val + -15}
					dominantBaseline='middle'
					textAnchor='middle'
					style={labelStyle}>
					{formatPercent(value)}
				</Chart.Label>
			</>
		)
	}

	return (
		<Chart data={data} height={window.innerWidth * 0.3}>
			<Title text='NATIONAL' textComponent={ChartTitleWithSpace} />
			<ArgumentAxis />
			<BarSeries
				valueField='val'
				argumentField='metric'
				pointComponent={BarWithLabel}
			/>
			<Animation />
		</Chart>
	)
}

export default memo(National)
