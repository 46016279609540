/**
 * This function programmatically computes consistent clamp rules based on the largest desired
 * value in that clamp. This allows all of our clamps to be uniform, and lowers the need for
 * pixel-pushing.
 *
 ** Note! All of the scalars below directly correspond with each other. You cannot change one
 ** without needing to change them all. Proceed with caution.
 *
 * @param {String} fullSize - the largest size desired in our clamp rule
 * @returns {String} – a css clamp rule
 */
export const clamp = (fullSize) => {
	// different clamps are returned, depending on the unit of fullSize
	if (fullSize?.endsWith('px')) {
		// parse out the number
		const number = Number(fullSize.substring(0, fullSize.length - 2))
		// and do some fancy maths.
		return `clamp(${number * 0.032}rem, ${number * 0.151}vw, ${number}px)`
	} else if (fullSize?.endsWith('pt')) {
		const number = Number(fullSize.substring(0, fullSize.length - 2))
		// keep a minimum fontsize, for legibility
		const smallestSize = Math.max(number * 0.0425, 0.75)
		return `clamp(${smallestSize}rem, ${number * 0.2}vw, ${number}pt)`
	} else if (fullSize?.endsWith('rem')) {
		const number = Number(fullSize.substring(0, fullSize.length - 3))
		return `clamp(${number * 0.75}rem, ${number * 3.75}vw, ${number}rem)`
	} else if (!Number.isNaN(Number(fullSize))) {
		// assume REM if no unit is provided
		const number = Number(fullSize)
		return `clamp(${number * 0.75}rem, ${number * 3.75}vw, ${number}rem)`
	} else return fullSize
}
