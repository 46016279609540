import { useState, useEffect, useCallback, memo } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Box, Typography, IconButton, useMediaQuery } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { formatNumberForDisplay } from '../../utils/format'
import { Enrollment, National, OverTime } from './charts'
import CityDataTable from './table'
import useStateData from '../../hooks/useStateData'
import { useNationalData } from '../../store/nationalData/context'
import StateSilhouette from '../StateSilhouette'
import quotes from '../../assets/quotes'
import { useTheme } from '@emotion/react'

const GovernorQuote = memo(function GovernorQuote({
	quote: { stateName, governorName, governorQuote, governorImage },
	isMobile,
}) {
	if (!governorName || !governorQuote) return null
	return (
		<>
			<Box
				sx={{
					borderRadius: '10px 10px 0 0',
					border: '1px solid',
					borderBottom: 'none',
					borderColor: 'lighter',
					backgroundColor: 'lightest',
					px: 6,
					py: 1,
				}}>
				<Typography sx={{ fontWeight: 600, fontSize: '21px' }}>
					{stateName} Governor — {governorName}
				</Typography>
			</Box>
			<Box
				sx={{
					borderRadius: '0 0 10px 10px',
					border: '1px solid',
					borderTop: 'none',
					borderColor: 'lighter',
					px: 6,
					py: 3,
					mb: 6,
					display: 'grid',
					gridTemplateColumns: isMobile ? '1fr' : 'auto 1fr',
					gridTemplateRows: isMobile ? 'auto 1fr' : '1fr',
					gap: 3,
				}}>
				<Box
					sx={{
						alignSelf: 'center',
						margin: 'auto',
						width: 100,
						height: 100,
						borderRadius: '50%',
						overflow: 'hidden',
						backgroundImage: `url(${governorImage})`,
						backgroundSize: 'cover',
						backgroundPosition: 'center',
					}}
				/>
				<Typography sx={{ fontWeight: 400, fontSize: '16px', fontWeight: 300 }}>
					{governorQuote}
				</Typography>
			</Box>
		</>
	)
})

const StateDetail = () => {
	const [filteredData, setFilteredData] = useState(null)
	const { stateName } = useParams()
	const nationalData = useNationalData()
	const stateData = useStateData(stateName)
	const navigate = useNavigate()
	const theme = useTheme()
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const handleCloseDialog = () => navigate('/')

	const quote = quotes[stateName]

	const latestData = filteredData?.[0]
	const numEligibleHouseholds = latestData?.households_acp_eligible

	useEffect(() => {
		if (nationalData) {
			setFilteredData(nationalData.filter((d) => d.state_name === stateName))
		}
	}, [stateName, nationalData])

	/* 
	 the following function and event listeners serve for the escape key to close the dialog and
	 navigate back to the national map, giving it the facade of being a modal dialog
	 */
	const escFunction = useCallback((event) => {
		if (event.key === 'Escape') {
			handleCloseDialog()
		}
	}, [])

	useEffect(() => {
		document.addEventListener('keydown', escFunction, true)
		return () => {
			document.removeEventListener('keydown', escFunction, true)
		}
	}, [])

	// this effect messages our WordPress overlords to update the size of the iframe
	useEffect(() => {
		setTimeout(() => {
			const message = {
				height: document.body.scrollHeight,
				width: document.body.scrollWidth,
				aspectRatio: document.body.scrollWidth / document.body.scrollHeight,
			}
			window.parent.postMessage(message, '*')
		}, 100)
	}, [stateData])

	if (!latestData) return null
	return (
		<Box
			sx={{
				p: 4,
			}}>
			<Box
				sx={{
					display: 'grid',
					width: '100%',
					gridTemplateColumns: '1fr 51px',
				}}>
				<div id='title'>
					<Typography
						sx={{ fontSize: 'min(45pt, 6vw)', whiteSpace: 'nowrap' }}
						color='primary'
						fontWeight='900'>
						{stateName} ACP Adoption
					</Typography>
					{numEligibleHouseholds && (
						<Typography
							color='dark'
							sx={{
								fontSize: 'min(24pt, 3vw)',
								whiteSpace: 'nowrap',
								mt: -1.5,
							}}
							gutterBottom
							fontWeight='500'>
							{formatNumberForDisplay(numEligibleHouseholds)} eligible
							households
						</Typography>
					)}
				</div>
				<Box
					sx={{
						display: 'grid',
						justifyItems: 'end',
						alignItems: 'start',
					}}>
					<IconButton
						edge='start'
						onClick={handleCloseDialog}
						aria-label='close-button'>
						<CloseIcon color='darker' fontSize='large' />
					</IconButton>
				</Box>
			</Box>
			<Box
				sx={{
					display: 'grid',
					gridTemplateColumns: isSmallScreen ? '1fr ' : '1fr 1fr 1fr',
					gridTemplateRows: isSmallScreen ? '1fr 1fr 1fr' : '1fr',
					gap: 3,
					'& > *': {
						borderRadius: '10px !important', // ?? why the heck does this need to be important?
						border: '1px solid',
						borderColor: 'lighter',
						minHeight: '300px',
					},
					mt: 4,
					mb: 6,
				}}>
				<Enrollment latestData={latestData} />
				<National stateName={stateName} latestData={latestData} />
				<OverTime stateData={filteredData} />
			</Box>
			{quote && <GovernorQuote quote={quote} isMobile={isMobile} />}
			<CityDataTable stateData={stateData} />
			<StateSilhouette stateName={stateName} />
		</Box>
	)
}

export default StateDetail
